div#size-btn-list li {
  display: inline;
  padding: 0px 4vw 0px 0px;
}

.size-button {
  background-color: transparent;
  border: 0.5px solid #1f1f1f;
  color: #1f1f1f;
  width: 8vh;
  height: 8vh;
  font-size: 2.2vh;
  cursor: pointer;
}

.disabled {
  background-color: transparent;
  border-style: solid;
  border-color: #757575(0, 0%, 46%);
  color: #757575;
  cursor: not-allowed;
  pointer-events: none;

  border: 1px solid #757575;
  position: relative;
}

.disabled:after {
  content: ''; 
  height: 1px;
  width: 141.421%;
  background-color: #757575;
  position: absolute;
  left: -1px;
  top: 0px; 
  transform: rotate(45deg);
  transform-origin: 1px 1px;
}

.enabled:hover {
  background-color: #eae7e1;
  color: #1f1f1f;
}

.enabled:focus {
  background-color: #eae7e1;
  outline: none;
}
