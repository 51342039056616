.cart-pop-up-main-container {
  position: absolute;
  z-index: 100;
  width: 32vw;
  height: 20vw;
  background-color: #d3cec7;
  box-shadow: -4px 0px 20px 0px #888888;
  right: 0;
}

.cart-pop-up-top-container {
  display: flex;
  flex-direction: row;
}

.cart-pop-up-heading {
  width: 24vw;
  margin: 2vw;
}

.cart-pop-up-heading h1 {
  font-size: 1.8vw;
}

.cart-pop-up-exit-btn {
  color: #1f1f1f;
  font-weight: 200;
  text-align: right;
}

.cart-pop-up-exit-btn:hover {
  cursor: pointer;
}

.cart-pop-up-exit-btn h3 {
  font-size: 1.2vw;
}

.cart-pop-up-item {
  display: flex;
  flex-direction: row;
}

.cart-pop-up-item-img {
  width: 12vw;
  margin: 0 3vw 0 2vw;
}

.cart-pop-up-right-container {
  width: 14vw;
}

.cart-pop-up-item-description {
  margin-top: -1vw;
  line-height: 3vw;
  font-size: 1vw;
}

.cart-pop-up-checkout-btn {
  width: 10vw;
  height: 2vw;
  border: solid;
  border-width: thin;
  border-color: #1f1f1f;
  text-align: center;
  padding-left: 0.2vw;
  margin-top: 1.5vw;
}

.cart-pop-up-checkout-btn h4 {
  font-size: 1vw;
  letter-spacing: 0.25vw;
}
