.partnerships {
  position: relative;
  margin: 0;
  padding: 0;
  height: 1000px;
}

.partnerships-tab-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 72%;
  height: 40px;
  z-index: 99;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}

.partnerships-tab-container h4 {
  font-size: 10px;
  letter-spacing: 2px;
  text-align: center;
}

#missionaries-tab {
  position: absolute;
  left: 0;
  width: 40%;
}

#organizations-tab {
  position: absolute;
  right: 0;
  width: 44%;
}

.partnerships-tab {
  width: 120px;
  text-align: center;
  position: relative;
}

.partnerships-active {
  border-bottom-width: 0.1px;
  border-bottom-style: solid;
}

.partnerships-tab > h4 {
  padding-bottom: 2px;
}

.partnerships-tab > h4:hover {
  cursor: pointer;
}

.partnerships-carousel-container {
  position: relative;
  width: 100%;
  height: 1000px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.partnerships-carousel-content-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 00px 0px 40px 0px;
  background-color: #d3cec7;
}

.partnerships-summary {
  position: absolute;
  top: 20px;
  width: 75%;
  height: 200px;
}
