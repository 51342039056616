.product-description-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  background-color: #1f1f1f;
  opacity: 0.5;
  transition: 0.5s;
}

.hidden {
  display: none;
  opacity: 0;
}

.product-overlay-exit-btn {
  font-size: 24px;
  color: #d3cec7;
  position: relative;
  float: right;
  cursor: pointer;
}

.product-des-container {
  position: fixed;
  width: 80vw;
  height: fit-content;
  left: 50%;
  top: 50vh;
  z-index: 10000;
  transform: translate(-50%, -50%);
  background-color: #eae7e1;
  padding: 20px;
}

.product-overlay-title {
  font-family: 'meno-banner', serif;
  font-size: 32px;
  margin: 0px 0px;
  font-weight: 200;
}

.product-overlay-verse {
  font-size: 12px;
  letter-spacing: 4px;
  margin: 1vw 0vw;
}

.product-overlay-description h3 {
  font-family: 'Inter', sans-serif;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
}

@media (max-width: 480px) {
  .product-des-container {
    width: 70vw;
    height: 80vh;
  }
  .product-overlay-title {
    font-size: 24px;
  }
  .product-overlay-verse {
    font-size: 12px;
    letter-spacing: 4px;
    margin: 2vw 0vw;
    padding-left: 0.75vw;
  }

  .product-overlay-description {
    height: 70vh;
    overflow: scroll;
    padding-top: 4vw;
  }

  .product-overlay-description h3 {
    margin: 0;
  }
}
