.mobile-partnerships-main-container {
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  background-color: #d3cec7;
  padding: 0;
  margin: 0;
}

.mobile-partnerships-main-container h1 {
  font-size: 20px;
}

.mobile-partnerships-main-container h3 {
  font-size: 12px;
  line-height: 1.5;
}

.mobile-partnerships-container {
  background-color: #eae7e1;
  width: 100%;
  height: 80%;
  padding-top: 60px;
  margin: 0;
}

.mobile-partnerships-container h1 {
  font-size: 28px;
}

.mobile-partnerships-caption-container {
  width: 64%;
  position: relative;
  left: 18%;
  text-align: center;
  padding-bottom: 12px;
}

.mobile-partnerships-image {
  position: relative;
  top: 0%;
  bottom: 0%;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 0;
}

.mobile-partnerships-image > img {
  display: block;
}

.mobile-tab-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 120%;
}

.mobile-in-touch-container {
  background-color: #eae7e1;
  position: relative;
  width: 100vw;
  padding-bottom: 4px;
  padding-top: 40px;
}

.mobile-in-touch-caption-container {
  position: relative;
  width: 64vw;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.mobile-in-touch-descripton > h3 {
  text-align: left;
  font-size: 8px;
}

.mobile-in-touch-caption {
  margin-bottom: 2vw;
}

.mobile-contact-btn {
  border: solid;
  border-width: thin;
  width: 55vw;
  letter-spacing: 2px;
  padding: 4px 4px;
  text-align: center;
  margin-bottom: 4px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-contact-btn > a > * {
  letter-spacing: 2px;
  font-size: 8px;
}
.mobile-contact-btn:hover {
  cursor: pointer;
}
