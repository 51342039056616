.shop-card-container {
  margin-bottom: 8vw;
  width: 33.3%;
}
.shop-card-image {
  margin-left: auto;
  margin-right: auto;
  width: 24vw;
  padding-bottom: 10px;
}

.shop-card-text {
  width: 24vw;
  margin: auto;
}

@media (max-width: 480px) {
  .shop-card-container {
    margin-bottom: 8vw;
    width: 50%;
  }

  .shop-card-image {
    width: 40vw;
  }
  .shop-card-text {
    width: 40vw;
  }
}
