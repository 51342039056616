.instagram-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 5vw;
}

.instagram-feed {
  margin-top: 2%;
  width: 80vw;
  display: flex;
  flex-direction: column;
}

.instagram-image-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.instagram-image {
  padding: 0.75vw 1.25vw 0.75vw;
}
