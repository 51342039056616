body {
  margin: 0;
}

.mobile-logo {
  position: absolute;
  top: 24px;
  left: 20px;
  width: 60px;
  z-index: 200;
}

#menubar {
  background-color: #d3cec7;
  position: fixed;
  right: -148px;
  float: right;
  align-items: flex-end;
  padding-top: 20px;
  height: 100vh;
  width: 148px;
  z-index: 200;
  box-shadow: -4px 0px 20px 0px #888888;
}

.menu-items-holder {
  position: fixed;
  right: -112px;
  margin-top: 20px;
}

.hamburger-icon {
  position: relative;
  width: 32px;
  float: right;
  top: 28px;
  right: 20px;
  z-index: 201;
}

.menubar-text {
  color: #1f1f1f;
  margin: 20px;
  font-family: 'Inter', sans-serif;
  text-align: right;
}

.menubar-text h4 {
  font-size: 12px;
  letter-spacing: 1.5px;
}

.show {
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 1s cubic-bezier(0.3, 0.54, 0.22, 1.09);
  transform: translateX(-100%);
}

.hide {
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transition: transform 2.5s cubic-bezier(0.22, 1.09, 0.3, 0.54);
  transform: translateX(100%);
}

.fixed {
  position: fixed;
}

#menu-toggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menu-toggle span {
  display: flex;
  width: 24px;
  height: 1px;
  margin-bottom: 5px;
  position: relative;
  background: #1f1f1f;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 2px 0px;
  transition: transform 0.5s linear, opacity 1s ease-in-out;
}

#menu-toggle span:first-child {
  transform-origin: 0% 100%;
}

#menu-toggle span:nth-last-child(2) {
  transform-origin: 0% 0%;
}

#menu-toggle input:checked ~ span:nth-last-child(1) {
  opacity: 1;
  transform: rotate(-45deg) translate(-4px, 3px);
}

#menu-toggle input:checked ~ span:nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menu-toggle input:checked ~ span:nth-last-child(3) {
  transform: rotate(45deg) translate(2px, 2px);
}

.mobile-bag {
  position: absolute;
  top: 12px;
  right: 72px;
  width: 40px;
  z-index: 100;
}

.mobile-bag-img {
  position: absolute;
  left: 0;
  top: 0;
}

.mobile-bag-num-background {
  position: absolute;
  right: 0px;
  top: 28px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.empty {
  background-color: transparent;
}

.items {
  background-color: #fefefe;
}

.mobile-bag-num {
  z-index: 100;
  font-size: 12px;
  color: #1f1f1f;
  text-align: center;
  position: relative;
  top: 0.6vw;
}
