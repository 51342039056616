.mobile-partnerships-card-item-container {
  display: inline-block;
  padding: 20px 20px 0px 20px;
}

.mobile-partnerships-card-item {
  width: 220px;
  height: 600px;
  background-color: #eae7e1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.mobile-partnerships-card-item h3 {
  font-size: 12px;
  line-height: 1.5;
}

.mobile-partnerships-card-item h2 {
  width: 100%;
  white-space: initial;
  font-size: 24px;
}

.mobile-partnerships-card-location {
  padding-top: 4px;
  margin: 0;
}

.mobile-partnerships-card-date {
  padding: 0;
  margin: 0;
}

.mobile-partnerships-card-description {
  width: 100%;
  height: 100px;
  white-space: initial;
}

.mobile-partnerships-card-image {
  position: relative;
  top: 0%;
  width: 100%;
  height: 220px;
  object-fit: contain;
  padding-top: 12px;
  padding-bottom: 8px;
}
