.img-carousel-main-container {
  width: 140%;
  display: flex;
  flex-direction: row;
  padding-top: 2vw;
  justify-content: space-around;
}

.img-carousel-item {
  width: 39vw;
  margin: 0 1vw;
}

.main-img-container {
  height: 24vw;
  width: 24vw;
}

.main-img-container > img {
  width: 100%;
}

.img-arrow-btn {
  width: 8vw;
  margin: 0 1.2vw;
  height: 16vw;
}

.img-left-arrow-box,
.img-right-arrow-box {
  position: relative;
}

.img-left-arrow-box-background,
.img-right-arrow-box-background {
  width: 2vw;
  height: 24vw;
  background-color: #fefefe;
  opacity: 0.1;
  box-shadow: -0.2vw 0 1vw -0.5vw #1f1f1f;
}

.img-arrow-btn:hover {
  cursor: pointer;
}

.img-arrow {
  width: 40%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-thumb-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.img-thumb {
  position: relative;
  height: 10%;
  margin: 0 2px;
}

.selected {
  border: solid;
  border-color: #1f1f1f;
  border-width: thin;
  padding: 0;
}

.img-thumb > img {
  width: 5.75vw;
  height: 5.75vw;
  display: block;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

@media (max-width: 480px) {
  .img-carousel-main-container {
    width: 70%;
    display: flex;
    flex-direction: row;
    padding-top: 8vw;
    margin-left: 15vw;
    justify-content: space-around;
  }

  .main-img-container {
    width: 100%;
    height: 80%;
  }

  .img-carousel-item {
    width: 100%;
  }

  .img-carousel-item img {
    width: 100%;
    margin: auto;
  }

  .img-arrow-btn {
    width: 18vw;
    height: 18vw;
  }

  .img-left-arrow-box,
  .img-right-arrow-box {
    position: relative;
  }

  .img-left-arrow-box-background,
  .img-right-arrow-box-background {
    width: 8vw;
    height: 70vw;
    background-color: #fefefe;
    opacity: 0.1;
    box-shadow: -0.2vw 0 1vw -0.5vw #1f1f1f;
  }

  .img-arrow-btn:hover {
    cursor: pointer;
  }

  .img-arrow {
    width: 40%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .img-thumb > img {
    width: 16vw;
    height: 16vw;
  }
}
