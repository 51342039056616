.mobile-scroll-item-container {
  display: inline-block;
  padding-right: 40px;
  padding-top: 20px;
}

.mobile-scroll-item {
  width: 220px;
  height: 600px;
  background-color: #eae7e1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.mobile-scroll-item h2 {
  font-size: 24px;
}

.mobile-scroll-item h3 {
  font-size: 12px;
  line-height: 1.5;
}

.mobile-scroll-release {
  padding: 0;
  margin: 0;
}

.mobile-scroll-description {
  width: 100%;
  height: 100px;
  white-space: initial;
  margin: 0;
  padding: 0;
}

.mobile-scroll-image {
  position: relative;
  top: 0%;
  width: 100%;
  height: 220px;
  object-fit: contain;
  padding-top: 12px;
  padding-bottom: 2px;
}

.mobile-shop-now-btn {
  position: relative;
  top: 192px;
  border: solid;
  border-width: 0.1px;
  padding: 4px 4px;
  text-align: center;
  background-color: transparent;
}

.mobile-shop-now-btn > a > * {
  letter-spacing: 2px;
  font-size: 8px;
}
