.mobile-staff-main-container a {
  width: 100%;
  height: 100%;
}

.mobile-staff-main-container h1 {
  font-size: 28px;
}

.mobile-staff-main-container h3 {
  font-size: 12px;
  line-height: 1.5;
}

.mobile-staff-main-container h4 {
  font-size: 12px;
}

.mobile-staff-main-container h6 {
  font-size: 12px;
}

.mobile-staff-main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.mobile-staff-top-container {
  background-color: #eae7e1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 5px;
  width: 100%;
}

.mobile-staff-caption-container {
  margin-left: 20px;
  width: 80%;
}

.mobile-staff-caption {
  padding-left: 20px;
}

.mobile-staff-description {
  width: 80%;
  padding-left: 20px;
  padding-bottom: 16px;
  margin-left: 20px;
}

.mobile-staff-container {
  background-color: #d3cec7;
  padding-bottom: 12px;
  width: 100%;
}

.mobile-staff-container-caption {
  padding-top: 28px;
  padding-left: 20px;
  padding-bottom: 28px;
  margin-left: 20px;
}

.mobile-staff-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 225px;
  padding-right: 8px;
}

.mobile-staff-image img {
  width: 104px;
  height: 156px;
}

.mobile-staff-image {
  width: 100px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 28px;
}

.mobile-staff-name h1 {
  font-size: 20px;
  text-align: center;
}

.mobile-staff-read {
  width: 10px;
  z-index: 10;
  position: relative;
  top: 128px;
  left: 96px;
}

.mobile-staff-read h3 {
  font-size: 6px;
  text-align: right;
}

.mobile-staff-more {
  background-color: white;
}

.mobile-staff-overlay-holder {
  background-color: #eae7e1;
  width: 325px;
  margin-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 100px;
  margin-bottom: 20px;
}

.mobile-staff-overlay-x img {
  width: 24px;
  height: 24px;
  margin-left: 284px;
}

.mobile-staff-overlay-position {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.mobile-staff-overlay-name h2 {
  font-size: 24px;
  text-align: center;
}

.mobile-staff-overlay-image {
  width: 190px;
  height: 285px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 68px;
}

.mobile-staff-overlay-image img {
  width: 190px;
  height: 285px;
}

.mobile-staff-overlay-caption {
  text-align: center;
  width: 300px;
  margin-left: 10px;
}
