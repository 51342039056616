.mobile-main-container a {
  width: 100%;
  height: 100%;
}

.mobile-main-container h1 {
  font-size: 28px;
}

.mobile-main-container h3 {
  font-size: 12px;
  line-height: 1.5;
}

.mobile-main-container h4 {
  font-size: 12px;
}

.mobile-main-container h6 {
  font-size: 12px;
}

.mobile-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
}

.mobile-top-container {
  background-color: #eae7e1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 12px;
}

.mobile-caption-container {
  position: relative;
  width: 304px;
  margin-top: 24px;
  margin-left: 20px;
  margin-bottom: 8px;
  justify-content: center;
}

#mobile-company-description {
  width: 224px;
}

.mobile-image-container {
  position: relative;
  margin-top: 8px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  height: 300px;
}

.mobile-shop-btn-container {
  width: 40%;
}

.mobile-main-images-section-container {
  position: relative;
  flex-direction: row;
  display: flex;
  width: 60%;
}

.mobile-main-blank-image-box {
  background-color: #d3cec7;
  width: 104px;
  height: 200px;
  position: relative;
  z-index: 0;
  left: 100px;
}

.mobile-main-image1-container {
  position: relative;
  width: 132px;
  z-index: 3;
  bottom: 168px;
  left: 20px;
}

.mobile-main-image2-container {
  position: relative;
  width: 156px;
  z-index: 4;
  bottom: 224px;
  left: 32px;
}

#main-image1,
#main-image2 {
  width: 100%;
}

.mobile-caption-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.mobile-caption-container > * {
  margin-left: 12px;
  width: 100px;
  margin-bottom: 4px;
}

#mobile-main-caption {
  margin-top: 44px;
  width: 304px;
}

.mobile-shop-btn {
  border: solid;
  border-width: thin;
  width: 112px;
  padding: 2px 4px;
  text-align: center;
  margin-left: 24px;
  margin-top: 116px;
}

#mobile-main-image1 {
  width: 100%;
}

#mobile-main-image2 {
  width: 100%;
}

.mobile-bottom-container {
  display: flex;
  flex-direction: column;
  background-color: #d3cec7;
  position: relative;
  padding-bottom: 12px;
}

.mobile-subscription-container {
  width: 100%;
  position: absolute;
}

#mobile-subscription-caption {
  position: relative;
  margin-top: 28px;
  margin-left: 30px;
  width: 100%;
  z-index: 1;
}

.mobile-description-container {
  background-color: #eae7e1;
  width: 300px;
  height: 256px;
  position: relative;
  left: 52px;
  top: 16px;
}

#mobile-subscription-description {
  width: 152px;
  position: relative;
  left: 136px;
  top: 20px;
  z-index: 100;
  line-height: 16px;
}

.mobile-subscription-btn {
  position: relative;
  left: 148px;
  top: 24px;
  border: solid;
  border-width: thin;
  width: 110px;
  padding: 5px;
  min-width: 48px;
  letter-spacing: 1px;
  text-align: center;
  z-index: 100;
}

.mobile-subscription-image-container {
  width: 100%;
  position: relative;
  left: 4px;
  top: 0;
  padding-bottom: 152px;
}

.mobile-subscription-image1-container {
  width: 156px;
  height: 156px;
  z-index: 1;
  position: relative;
  left: 16px;
  top: 160px;
}

.mobile-subscription-image2-container {
  width: 112px;
  z-index: 1;
  position: relative;
  left: 204px;
  top: 136px;
}

#subscription-image1,
#subscription-image2 {
  width: 100%;
}
