.design-main-container {
  background-color: #eae7e1;
}

.design-top-container {
  width: 100%;
}

.design-caption-container {
  width: 23vw;
  height: 31vw;
  position: absolute;
  left: 11vw;
  top: 16vw;
  background-color: #eae7e1;
}

.design-caption {
  width: 60%;
  position: relative;
  left: 6vw;
  top: 6vw;
}

.design-caption > * {
  margin-bottom: 0.5vw;
}

.design-image-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.design-image-container > img {
  width: 100%;
  height: 100%;
}

.design-process-container {
  width: 100%;
  background-color: #eae7e1;
  position: relative;
  bottom: 1vw;
  display: flex;
  flex-direction: row;
  padding-bottom: 15vw;
}

.design-process-caption-container {
  position: relative;
  width: 38.5vw;
  top: 10vw;
}

.design-process-title {
  position: relative;
  top: 5vw;
  text-align: center;
}

.process-image-container {
  position: absolute;
  top: 23vw;
  right: left;
  width: 80%;
}

.design-process-description-container {
  background-color: #d3cec7;
  width: 48vw;
  position: relative;
  top: 5vw;
  padding: 3vw;
}

.design-process-description-container > * {
  margin-bottom: 3.5vw;
}

.design-process-description-title > h3 {
  margin-top: 0.5vw;
}

.word-container,
.design-container,
.release-container {
  padding-top: 2vw;
  border-top: 0.1vw solid #707070;
}

.release-container {
  margin-bottom: 1vw;
}

.learn-more-btn {
  border: solid;
  border-width: thin;
  width: 10vw;
  letter-spacing: 2px;
  padding: 0.2vw 1.2vw;
  text-align: center;
  margin-bottom: 1vw;
}

.learn-more-btn > h4 {
  font-size: 1.1vw;
}

.learn-more-btn:hover {
  cursor: pointer;
}

@media (max-width: 480px) {
}
