.connect-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

.connect-container > * {
  width: 60vw;
}

.connect-with-us {
  text-align: center;
  margin-bottom: 3vw;
}

.connect-fb,
.connect-ig,
.connect-medium {
  position: relative;
  width: 60%;
  height: 10vw;
  display: flex;
  flex-direction: row;
  left: 1vw;
  margin: 8px 0;
}

.connect-fb-title,
.connect-ig-title,
.connect-medium-title {
  height: 3vw;
  width: 180px;
}

.connect-fb-title h4,
.connect-ig-title h4,
.connect-medium-title h4 {
  font-size: 8px;
}

.connect-fb-caption-container,
.connect-ig-caption-container,
.connect-medium-caption-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 36vw;
  height: 6vw;
}

.connect-fb-caption,
.connect-ig-caption,
.connect-medium-caption {
  height: 3vw;
}

.connect-fb-caption h5,
.connect-ig-caption h5,
.connect-medium-caption h5 {
  width: 180px;
  height: 3vw;
  margin: 0;
  font-size: 8px;
}

.connect-fb-icon,
.connect-ig-icon,
.connect-medium-icon {
  width: 36px;
  opacity: 0.2;
  margin: 0px 12px;
}

.mobile-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.mobile-footer-social-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  z-index: 2;
}

.mobile-footer-blurb {
  width: 160px;
  position: relative;
  padding-bottom: 2vw;
  padding-left: 3vw;
  line-height: 1rem;
}

.mobile-footer-blurb h5 {
  font-size: 8px;
}

.mobile-yhwh-berkeley {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.mobile-yhwh-berkeley h5 {
  font-size: 8px;
}

a {
  width: 100%;
  height: 2.2vw;
}

img {
  width: 100%;
}

.mobile-bottom-border {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 65%;
  z-index: 1;
}

.mobile-bottom-border > img {
  display: block;
}

.mobile-social-icons-container {
  position: relative;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 6vw;
}

.mobile-icon {
  width: 36px;
  opacity: 0.2;
}

@media (min-width: 769px) {
  .connect-container {
    display: none;
  }
  .mobile-footer-container {
    display: none;
  }
}
