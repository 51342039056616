.shop-main-container {
  padding-top: 10%;
  background-color: #eae7e1;
}

.shop-top-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 45vw;
  z-index: 100;
}
.shop-blank-image-box-one {
  background-color: #d3cec7; /*#EAE7E1*/
  width: 75%;
  height: 93%; /*45vw */
  position: absolute;
  z-index: 0;
  top: 0%;
}
.shop-caption-container {
  display: flex;
  flex-direction: column;
  width: 28vw;
  padding: 8%;
  padding-left: 8%;
  padding-top: 10%;
  z-index: 1;
}
.shop-title {
  padding-bottom: 12px;
  z-index: 100;
}
.shop-caption {
  padding-bottom: 72px;
  z-index: 1;
}
.shop-top-image {
  /* display: flex; */
  position: absolute;
  top: 5%;
  width: 56%;
  right: 0%;
  z-index: 100;
}
.shop-top-image > img {
  display: block;
  margin-top: auto;
  top: 30%;
}
.shop-btn {
  border: solid;
  border-width: thin;
  width: 9.5vw;
  letter-spacing: 0.15vw;
  padding: 0.15vw 1.2vw;
  text-align: center;
  z-index: 100;
}

.shopcard-container {
  width: 100%;
}

.shopcard-col {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10% 10% 20%;
  z-index: 100; */

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10vw 0vw;
  z-index: 100000;
}

.shopcard-row {
  display: flex;
  flex-direction: row;
  padding: 0vw 4vw;
  z-index: 100;
}

.shop-blank-image-box-two {
  background-color: #d3cec7; /*D3CEC7*/
  width: 80vw; /*100*/
  height: 20vw; /*60*/
  position: absolute;
  z-index: 0;
  top: 36%;
  right: 0;
}
.shop-blank-image-box-three {
  background-color: #d3cec7; /*D3CEC7*/
  width: 80vw; /*100*/
  height: 20vw; /*60*/
  position: absolute;
  z-index: 0;
  top: 62%;
  left: 0;
}
