.new-drop-container {
  position: absolute;
  z-index: 400;
}

.new-drop-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 200;
  background-color: #1f1f1f;
  opacity: 0.5;
  transition: 0.5s;
}

.new-drop-container > * {
  z-index: 200;
}

.new-drop-overlay-img {
  position: absolute;
  z-index: 0;
  width: 100%;
}

.hidden {
  display: none;
  opacity: 0;
}

.new-drop-content-container {
  position: fixed;
  width: 60vw;
  height: 40vw;
  /* height: 70vh; */
  left: 50%;
  top: 50vh;
  z-index: 250;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-drop-overlay-header {
  position: absolute;
  top: 8%;
  text-align: center;
  width: 30%;
  color: #1f1f1f;
}

.new-drop-overlay-header > h3 {
  margin-bottom: 1%;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.1vw;
}

.new-drop-overlay-header-underline {
  position: absolute;
  top: 17%;
  width: 17%;
  height: 1px;
  background-color: #1f1f1f;
}

.new-drop-overlay-title {
  position: absolute;
  top: 44%;
  transform: translateY(-65%);
  text-align: center;
  color: #1f1f1f;
}

.new-drop-overlay-title > h1 {
  font-size: 6vw;
}

.new-drop-overlay-description {
  position: absolute;
  width: 32vw;
  text-align: center;
  top: 48%;
}

.new-drop-overlay-description h3 {
  color: #1f1f1f;
}

.new-drop-overlay-exit-btn {
  position: absolute;
  top: 4%;
  right: 4%;
  color: #1f1f1f;
}

.new-drop-overlay-exit-btn > h3 {
  margin: 0;
  font-weight: 500;
  letter-spacing: 1px;
}

.new-drop-overlay-exit-btn:hover {
  cursor: pointer;
}

.new-drop-overlay-order-btn {
  position: absolute;
  top: 72%;
  border: solid;
  border-width: thin;
  width: 11vw;
  padding: 0.2vw 0.5vw;
  text-align: center;
  color: #1f1f1f;
}

.new-drop-overlay-order-btn > a > * {
  letter-spacing: 2px;
  font-size: 1.1vw;
  color: #1f1f1f;
}

/* @media (max-width: 480px) {
  .video-container {
    height: 75vh;
    width: 100vw;
  }
} */
