.navbar-container {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  padding-bottom: 2vw;
}

.logo,
.navlinks {
  padding-top: 2vw;
}

.logo {
  width: 7vw;
  margin-left: 7.5vw;
}

.navlinks {
  position: absolute;
  right: 0;
  width: 53vw;
  margin-right: 10vw;
}

ul {
  width: 100%;
  font-family: 'Inter', 'Georgia', sans-serif;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin-top: 1vw;
}

li {
  font-size: 1vw;
  padding-bottom: 0.3vw;
}

a {
  text-decoration: none;
}

.navlink > .navlinkTitle::after {
  background: none repeat scroll 0 0 transparent;
  content: '';
  display: block;
  height: 0.05vw;
  left: 50%;
  position: relative;
  top: 0.5vw;
  background: #1f1f1f;
  transition: width 0.3s ease 0s, left 0.3s ease;
  width: 0;
}

.navlink > .navlinkTitle:hover:after {
  width: 100%;
  left: 0;
}

#logo-image {
  width: 100%;
}

.navlink {
  color: #1f1f1f;
  letter-spacing: 0.1vw;
}

.navlinkTitle{
  color: #1f1f1f;
  letter-spacing: 0.1vw;
  text-align: center;
  /*margin: 0px px 0px 10px;*/
}

.navlink:hover {
  cursor: pointer;
  padding-bottom: 50px;
}

.bag {
  width: 3vw;
  margin-right: 4vw;
  margin-top: 1.75vw;
}

.bag-img {
  position: absolute;
  left: 0;
  top: 0;
}

.bag-num-background {
  position: absolute;
  right: 3.8vw;
  top: 3.75vw;
  width: 1.4vw;
  height: 1.4vw;
  border-radius: 50%;
}

.empty {
  background-color: transparent;
}

.items {
  background-color: #fefefe;
}

.bag-num {
  z-index: 100;
  font-size: 0.8vw;
  color: #1f1f1f;
  text-align: center;
  position: relative;
  top: 0.2vw;
}
