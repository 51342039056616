a {
  width: 100%;
  height: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.top-container {
  background-color: #eae7e1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.caption-container,
.image-container {
  position: relative;
  width: 50vw;
  margin-top: 10vw;
  margin-bottom: 2vw;
}

.main-blank-image-box {
  background-color: #d3cec7;
  width: 28vw;
  height: 40vw;
  position: absolute;
  z-index: 0;
  top: 2vw;
  left: 14vw;
}

.main-image1-container {
  position: relative;
  width: 20vw;
  z-index: 3;
  top: 9vw;
  left: 2vw;
}

.main-image2-container {
  position: relative;
  width: 24vw;
  z-index: 4;
  top: 0vw;
  left: 9vw;
}

#main-image1,
#main-image2 {
  width: 100%;
}

.caption-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.caption-container > * {
  margin-left: 7.5vw;
  width: 37.5vw;
  margin-bottom: 0.75vw;
}

#main-caption {
  margin-top: 10.5vw;
}

.shop-btn {
  border: solid;
  border-width: thin;
  width: 9.5vw;
  letter-spacing: 0.15vw;
  padding: 0.15vw 1.2vw;
  text-align: center;
}

#main-image {
  width: 100%;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  background-color: #d3cec7;
  position: relative;
}

.subscription-container {
  width: 100%;
  position: absolute;
}

#subscription-caption {
  position: relative;
  margin-top: 12vw;
  margin-left: 10vw;
  z-index: 1;
}

.description-container {
  background-color: #eae7e1;
  width: 50vw;
  height: 45vw;
  position: relative;
  left: 32.5vw;
  bottom: 8vw;
}

#subscription-description {
  width: 50%;
  position: relative;
  left: 20vw;
  top: 9vw;
  z-index: 100;
}

.subscription-btn {
  position: relative;
  left: 20vw;
  top: 9vw;
  border: solid;
  border-width: thin;
  width: 19vw;
  min-width: 19vw;
  letter-spacing: 2px;
  text-align: center;
  z-index: 100;
}

.subscription-image-container {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  padding-bottom: 13vw;
}

.subscription-image1-container {
  width: 30vw;
  z-index: 1;
  position: relative;
  left: 16vw;
  top: 21vw;
}

.subscription-image2-container {
  width: 22vw;
  z-index: 1;
  position: relative;
  left: 50vw;
  top: 8vw;
  bottom: 3vw;
}

#subscription-image1,
#subscription-image2 {
  width: 100%;
}

@media (max-width: 480px) {
}
