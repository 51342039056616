.dd_menu {
    overflow: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dd_menu li {
    display: block; 
    float: none;
    padding: 5px;
}

.dropdown-menu {
    width: 100%;
    height: auto;
}

.dropdown-item {
    color: #1f1f1f;
    font-family: 'Inter', 'Georgia', sans-serif;
}