.checkout-main-container {
  position: absolute;
  background-color: #eae7e1;
  width: 100%;
  height: auto;
}

.checkout-shopping-bag-container {
  position: relative;
  width: 100%;
  height: inherit;
  background-color: #eae7e1;
  margin-bottom: 10vw;
}

.checkout-shopping-bag-title {
  margin: 12vw 0vw 8vw;
  position: relative;
  text-align: center;
}

.checkout-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.checkout-items-list {
  width: 48%;
  margin-left: 6vw;
}

.checkout-item {
  background-color: #eae7e1;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 8vw;
  border-bottom: 0.1vw solid #1f1f1f;
  margin-bottom: 4vw;
}

.checkout-img-link {
  width: 12vw;
  height: 8vw;
  padding-right: 2vw;
}
.checkout-item-img {
  width: 100%;
}

.checkout-item-img:hover {
  cursor: pointer;
}

.checkout-item-img > img {
  width: 100%;
  display: block;
}

.checkout-item-delete {
  width: 2%;
  position: absolute;
  right: 48%;
}

.checkout-item-delete:hover {
  cursor: pointer;
}

.checkout-summary {
  padding: 3vw 3vw 4vw;
  width: 32vw;
  background-color: #d3cec7;
  height: 34.5vw;
  margin-left: 4vw;
  position: sticky;
  top: 3vw;
}

.checkout-summary-title {
  border-bottom: 0.1vw solid #1f1f1f;
  padding-bottom: 1vw;
}
.checkout-summary-title h2 {
  font-size: 2.5vw;
}

/* .checkout-summary-note {
  width: 100%;
  border-bottom: 0.1vw solid #1f1f1f;
  padding: 1vw 0vw 4vw 0vw;
}

.checkout-summary-note-description {
  margin-bottom: 1vw;
}

.checkout-summary-note-textbox {
  width: 100%;
  height: 4vw;
}

.checkout-summary-note-textbox > textarea {
  padding: 0;
  width: 100%;
  font-size: 24px;
  border: none;
  height: 6vw;
  resize: none;
}

.checkout-summary-note-textbox > textarea:hover,
.checkout-summary-note-textbox > textarea:active,
.checkout-summary-note-textbox > textarea:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
} */

.checkout-shipping-option > h3 {
  font-size: 0.8vw;
  margin: 0;
}

.checkout-shipping-info-container {
  margin-top: 1vw;
  width: 100%;
  padding-bottom: 1vw;
  border-bottom: 0.1vw solid #1f1f1f;
}

.checkout-shipping-info-description {
  margin-bottom: 1vw;
  margin-top: 0;
}

.checkout-shipping-option {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2vw;
}

.shipping-option {
  height: 1.8vw;
  width: 1vw;
  background-color: #eae7e1;
  border: solid;
  border-width: thin;
  margin-right: 20px;
}

.checkout-summary-promo {
  width: 100%;
  padding-top: 1vw;
}

.checkout-summary-promo-description {
  margin-bottom: 1vw;
}

.checkout-summary-promo-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 1vw;
}

.checkout-summary-promo-textbox {
  width: 90%;
}

.checkout-summary-promo-textbox > textarea {
  padding: 0;
  width: 90%;
  font-size: 2vw;
  border: none;
  height: 2.5vw;
  resize: none;
}

.checkout-summary-promo-textbox > textarea:hover,
.checkout-summary-promo-textbox > textarea:active,
.checkout-summary-promo-textbox > textarea:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.checkout-summary-promo-btn {
  width: 8vw;
  height: 2.4vw;
  background-color: #fefefe;
  border-width: thin;
  text-align: center;
  letter-spacing: 0.15vw;
}

.checkout-summary-promo-btn:hover {
  cursor: pointer;
}

.checkout-summary-subtotal > h3 {
  font-size: 2vw;
  letter-spacing: 4px;
  margin: 0;
}

.checkout-summary-button {
  margin-top: 2vw;
}

.checkout-summary-button:hover {
  cursor: pointer;
}

@media (max-width: 480px) {
  .checkout-container {
    display: flex;
    flex-direction: column;
  }

  .checkout-shopping-bag-title {
    margin: 24vw 0vw 12vw;
  }

  .checkout-main-container h4 {
    font-size: 3vw;
  }

  .checkout-main-container h3 {
    font-size: 2vw;
    line-height: 1.5;
    margin: 1.5vw 0;
  }

  .checkout-main-container h5 {
    font-size: 2vw;
    line-height: 1.5;
    margin: 1vw 0;
  }

  .checkout-items-list {
    width: 80%;
    margin: auto;
  }

  .checkout-item {
    padding-bottom: 12vw;
    margin-bottom: 8vw;
  }

  .checkout-item-img {
    width: 180%;
  }

  .checkout-img-link {
    padding-right: 14vw;
  }

  .checkout-item-delete {
    width: 4vw;
    position: absolute;
    right: 12%;
  }

  .checkout-summary {
    padding: 3vw 6vw 6vw;
    width: 72vw;
    height: 100vw;
    margin: 8vw auto;
  }

  .checkout-summary-title {
    padding: 2vw 0;
  }
  .checkout-summary-title h2 {
    font-size: 8vw;
  }

  .checkout-shipping-info-container {
    margin-top: 2vw;
    padding-bottom: 4vw;
  }

  .checkout-shipping-option {
    margin: 2vw 0 4vw;
  }

  .shipping-option {
    height: 16px;
    width: 16px;
    margin-right: 20px;
  }

  .checkout-summary-promo-textbox > textarea {
    font-size: 12px;
    height: 5.5vw;
  }

  .checkout-summary-promo-btn {
    width: 16vw;
    height: 4vw;
    padding-bottom: 2vw;
  }

  .checkout-summary-subtotal > h3 {
    font-size: 8vw;
  }

  .checkout-summary-button {
    width: 100%;
    text-align: center;
    bottom: 2vw;
    border-width: thin;
    letter-spacing: 0.15vw;
    padding: 0.2vw 0vw;
    margin-top: 2vw;
  }
}
