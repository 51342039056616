.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #eae7e1;
}

h3 {
  line-height: 2.25vw;
}

.about-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 10vw;
  margin-bottom: 9vw;
}

.about-caption-container {
  width: 35vw;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 7vw;
  margin-bottom: 15vw;
}

.about-caption-container > * {
  width: 27vw;
  position: relative;
  left: 6vw;
}

.about-caption {
  margin-bottom: 1.5vw;
}

.about-description {
  margin-bottom: 0.75vw;
}

.video-btn {
  border: solid;
  border-width: thin;
  width: 10vw;
  padding: 0.25vw 1.2vw;
  text-align: center;
}

.video-btn > h4 {
  letter-spacing: 0.1 vw;
}

.video-btn:hover {
  cursor: pointer;
}

.about-image-container {
  position: relative;
  width: 65vw;
}

.about-image1-container {
  position: absolute;
  top: 20vw;
  left: 10vw;
  width: 23vw;
  z-index: 2;
}

.about-image1-container > img {
  width: 100%;
}

.about-blank-image-box {
  background-color: #d3cec7;
  width: 32vw;
  height: 36vw;
  position: absolute;
  z-index: 0;
  left: 27vw;
}

.vision-mission-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 8vw;
  margin-bottom: 2vw;
}

.vision-mission-blank-image1-box {
  background-color: #d3cec7;
  width: 52vw;
  height: 78vw;
  position: absolute;
  z-index: 0;
}

.vision-mission-blank-image2-box {
  background-color: #d3cec7;
  width: 26vw;
  height: 30vw;
  position: absolute;
  z-index: 0;
  left: 63vw;
  top: 30vw;
}

.vision-mission-image1-container {
  position: absolute;
  width: 27vw;
  left: 6vw;
  top: -6vw;
}

.vision-mission-image2-container {
  position: absolute;
  width: 30vw;
  left: 13vw;
  top: 18vw;
}

.vision-mission-image3-container {
  position: absolute;
  width: 25vw;
  right: 0vw;
  top: 47vw;
}

.vision-container {
  width: 42vw;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 47vw;
  top: 3vw;
  margin-bottom: 13vw;
}

.vision-caption {
  margin-bottom: 1.5vw;
}

.quote {
  width: 30vw;
  position: relative;
  left: 57vw;
  top: 3vw;
  margin-bottom: 7vw;
}

.mission-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 37vw;
  left: 6vw;
  top: 3vw;
}

.mission-caption {
  margin-bottom: 1.5vw;
}

@media (max-width: 480px) {
}
