.about-form {
  position: relative;
  margin-bottom: 3vw;
}

.form {
  position: relative;
  left: 50vw;
  transform: translateX(-50%);
  width: 68vw;
  height: 45vw;
  display: flex;
  flex-direction: row;
}

.form-tab-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 45vw;
  transform: rotate(-90deg);
  z-index: 0;
  position: relative;
  right: 4vw;
}

.form-tab {
  height: 10vw;
  width: 20vw;
  text-align: center;
  position: relative;
  padding-top: 1vw;
  border-radius: 3.5vw;
}

.active {
  background-color: #fefefe;
}

.form-tab > h4:hover {
  cursor: pointer;
}

.form-container {
  background-color: #fefefe;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3.5vw;
}

.form-content-container {
  width: 75%;
  height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.form-input-container {
  width: 100%;
}

.form-input-container > * {
  margin: 1.5vw 0 1.5vw;
}

.form-input-container:last-child {
  margin: 0;
}

.subscribe_form-submit-btn {
  border: solid;
  border-width: thin;
  width: 9.5vw;
  letter-spacing: 0.15vw;
  padding: 0.15vw 1.2vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.subscribe_form-submit-btn:hover {
  cursor: pointer;
}

.contact_form-submit-btn {
  border: solid;
  border-width: thin;
  width: 9.5vw;
  letter-spacing: 0.15vw;
  padding: 0.15vw 1.2vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.contact_form-submit-btn:hover {
  cursor: pointer;
}

input {
  padding: 0.25vw 1vw;
  width: 95%;
  height: 3vw;
  font-size: 1.5vw;
}

input:focus {
  outline: none;
}

@media (max-width: 480px) {
  .about-form h4 {
    font-size: 8px;
  }

  .about-form {
    margin-top: 60px;
  }

  .form {
    width: 92%;
    height: 36vh;
    flex-direction: column;
  }

  .form-tab-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    transform: rotate(0deg);
    z-index: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
  }

  .form-tab {
    height: 48px;
    width: 50%;
    text-align: center;
    position: relative;
    border-radius: 16px;
  }

  .form-tab > * {
    position: relative;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .form-submit-btn {
    width: 52px;
  }
}
