.partnerships-main-container {
  position: absolute;
  width: 100vw;
}

.partnerships-container {
  background-color: #eae7e1;
  width: 100vw;
  height: 65vw;
}

.partnerships-caption-container {
  width: 33vw; /*22*/
  position: relative;
  left: 7vw;
  top: 13vw;
}

.parternships-caption {
  width: 100%;
}

.partnerships-description {
  width: 100%;
}

.partnerships-blank-image-box {
  position: absolute;
  right: 0vw; /*35*/
  top: 16vw;
  background-color: #d3cec7;
  width: 44vw; /*57*/
  height: 38vw; /*30*/
}

.great-commission-verse {
  position: relative;
  top: 7vw;
  left: 6vw;
}

.great-commission-bible-ref {
  position: relative;
  top: 8vw;
  left: 25vw;
}

.partnerships-image {
  position: absolute;
  width: 44vw; /*40vw*/
  left: 49vw;
  top: 20vw; /*35vw*/
  z-index: 3;
}

.partnerships-blank-image-box-skinny {
  position: absolute;
  left: 0vw; /*35*/
  top: 35vw;
  background-color: #d3cec7;
  width: 38vw; /*57*/
  height: 9vw; /*30*/
}

.missionaries-container {
  position: relative;
  background-color: #d3cec7;
}

.missionaries-caption-container {
  position: absolute;
  background-color: #eae7e1;
  width: 35.46vw;
  height: 60vw;
  left: 11vw;
  top: 5vw;
}

.missionaries-caption-container > * {
  width: 67.4%;
  position: relative;
  top: 6vw;
  left: 50%;
  transform: translateX(-50%);
}

.missionaries-description {
  margin-top: 2vw;
}

.missionaries-philippines-container {
  position: relative;
  width: 53.6vw;
  left: 46.43vw;
  top: 22.5vw;
  margin-bottom: 20vw;
}

.missionaries-philippines-image {
  margin-bottom: 2vw;
}

.missionaries-philippines-title,
.missionaries-philippines-description,
.missionaries-philippines-time-date {
  width: 90%;
  margin-left: 2vw;
  margin-bottom: 1vw;
}

.missionaries-eads-container {
  position: relative;
  margin-bottom: 10vw;
}

.missionaries-eads-blank-image-box {
  position: absolute;
  width: 50vw;
  height: 25vw;
  left: 15vw;
  top: 10vw;
  background-color: #eae7e1;
}

.missionaries-eads-image {
  position: relative;
  width: 33vw;
  left: 5vw;
}

.missionaries-eads-title,
.missionaries-eads-time-date {
  position: relative;
  left: 40vw;
  bottom: 9vw;
  margin-bottom: 1vw;
}

.missionaries-eads-description {
  position: relative;
  left: 15vw;
  width: 74vw;
  bottom: 8vw;
}

.missionaries-david-container {
  position: relative;
  width: 100vw;
  height: 38vw;
  margin-bottom: 12vw;
}

.missionaries-david-blank-image-box {
  background-color: #eae7e1;
  width: 84.11%;
  height: 38vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.missionaries-david-image {
  position: relative;
  left: 13vw;
  bottom: 3vw;
  width: 26.5vw;
}

.missionaries-david-title,
.missionaries-david-time-date {
  position: relative;
  left: 13vw;
  bottom: 1vw;
  margin-bottom: 1vw;
}

.missionaries-david-description {
  position: relative;
  left: 45vw;
  bottom: 30vw;
  width: 40vw;
}

.missionaries-isabella-johannah-container {
  position: relative;
  margin-bottom: 18vw;
}

.missionaries-isabella-johannah-blank-image-box {
  position: absolute;
  background-color: #eae7e1;
  width: 35vw;
  height: 33vw;
  left: 45vw;
  top: 3.6vw;
}

.missionaries-isabella-johannah-image1 {
  position: absolute;
  width: 17.45vw;
  left: 53vw;
  top: 9vw;
}

.missionaries-isabella-johannah-image2 {
  position: absolute;
  width: 17.45vw;
  left: 73vw;
  top: -4vw;
}

.missionaries-isabella-johannah-title,
.missionaries-isabella-johannah-time-date,
.missionaries-isabella-johannah-description {
  position: relative;
  width: 32vw;
  left: 7vw;
  margin-bottom: 1vw;
}

.missionaries-briana-container {
  position: relative;
  padding-bottom: 9vw;
}

.missionaries-briana-image {
  position: relative;
  width: 30vw;
  left: 10vw;
  bottom: 5vw;
  z-index: 6;
}

.missionaries-briana-title,
.missionaries-briana-time-date {
  position: relative;
  left: 11.5vw;
  margin-top: 1vw;
  bottom: 3vw;
}

.missionaries-briana-description {
  background-color: #eae7e1;
  position: absolute;
  width: 46vw;
  height: 43vw;
  left: 37vw;
  top: 0;
}

.missionaries-briana-description > h3 {
  position: relative;
  margin-top: 0;
  width: 34vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.organizations-container {
  background-color: #eae7e1;
  padding-top: 10vw;
  padding-bottom: 8vw;
}

.organizations-caption {
  position: relative;
  left: 9vw;
}

.organizations-laundry-love {
  position: relative;
  left: 9vw;
  display: flex;
  flex-direction: row;
}

.organizations-laundry-love-caption-container {
  position: relative;
  width: 40vw;
  margin-top: 3vw;
}

.organizations-laundry-love-caption-container > * {
  margin-bottom: 1vw;
}

.organizations-laundry-love-image {
  position: relative;
  width: 35vw;
  left: 6vw;
}

.organizations-letters-of-hope {
  position: relative;
  left: 9vw;
  display: flex;
  flex-direction: row;
}

.organizations-letters-of-hope-caption-container {
  position: relative;
  width: 40vw;
  margin-top: 8vw;
  left: 6vw;
}

.organizations-letters-of-hope-caption-container > * {
  margin-bottom: 1vw;
}

.organizations-letters-of-hope-image {
  position: relative;
  width: 35vw;
  margin-top: 8vw;
}

.in-touch-container {
  background-color: #eae7e1;
  position: relative;
  width: 100vw;
  padding-bottom: 4vw;
}

.in-touch-caption-container {
  position: relative;
  width: 60vw;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.in-touch-descripton > h3 {
  text-align: left;
}

.in-touch-caption {
  margin-bottom: 2vw;
}

.contact-btn {
  margin-top: 3vw;
  border: solid;
  border-width: thin;
  width: 10vw;
  padding: 0.25vw 1.2vw;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
