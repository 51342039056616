.member-main-container {
  width: 100vw;
  background-color: #eae7e1;
  display: flex;
  flex-direction: row;
  padding: 4vw 0 4vw;
  justify-content: space-around;
}

.member-odd {
  width: 76%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.member-even {
  width: 76%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  text-align: right;
}

.member-description-container {
  position: relative;
  width: 60%;
  padding: 0vw 2vw;
}

.member-name {
  font-size: 36px;
}

.member-role {
  font-family: 'meno-banner', serif;
  font-size: 1.5vw;
  margin: 0px 0px;
  font-weight: 200;
  padding-top: 2vw;
}

.member-description {
  font-size: 16px;
}

.member-image {
  padding: 0vw 2vw;
  width: 40%;
}
