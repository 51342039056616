.about-video-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 200;
  background-color: #1f1f1f;
  opacity: 0.5;
  transition: 0.5s;
}

.about-video-container > img {
  width: 2vw;
  height: 2vw;
  position: fixed;
  right: 1vw;
  top: 1vw;
}

.hidden {
  display: none;
  opacity: 0;
}

.video-container {
  position: fixed;
  width: 75vw;
  left: 50%;
  top: 50vh;
  z-index: 250;
  transform: translate(-50%, -50%);
  background-color: #1f1f1f;
}

@media (max-width: 480px) {
  .video-container {
    height: 75vh;
    width: 100vw;
  }
}
